
import { InjectionToken } from '@angular/core';

export const API_SERVICE_CONFIG = new InjectionToken('api-service.config');


// const BASE_URL = 'https://ohana-qa.summaofficesupplies.com/admin/public/api/v1';
// const BASE_URL = 'https://qa.ohanaofficeproducts.com/admin/public/api/v1';
const BASE_URL = 'https://coasttocoastofficesupply.com/admin/public/api/v1';
// const BASE_URL = 'https://costtocostapi.summaofficesupplies.com/admin/public/api/v1';
// const BASE_URL = 'https://ohana-dev.summaofficesupplies.com/admin/public/api/v1';
// const BASE_URL = 'https://qa.summaofficesupplies.com/admin/public/api/v1';


// const IMG_URL = 'https://ohana-dev.summaofficesupplies.com/admin/public/';
// const IMG_URL = 'https://ohana-qa.summaofficesupplies.com/admin/public/';
const IMG_URL = 'https://coasttocoastofficesupply.com/admin/public/';
// const IMG_URL = 'https://costtocostapi.summaofficesupplies.com/admin/public/';
// const IMG_URL = 'https://qa.ohanaofficeproducts.com/admin/public/';
// const IMG_URL = 'https://qa.summaofficesupplies.com/admin/public/';



const HEADERS = {
  'Content-Type': 'application/json'
};


const AUTHORIZE_HEADERS = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
};

export const DEFAULT_API_SERVICE_CONFIG = {
  headers: HEADERS,
  imageUrl: IMG_URL,
  authHeader: AUTHORIZE_HEADERS,
  userConfig: {
    apiPrefixName: `${BASE_URL}/`
  }
};


